import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from '@app/app.component';
import { formlyConfig } from '@app/formly';
import { ContactUsModalComponent } from '@app/root/components/contact-us-modal/contact-us-modal.component';
import { PrivacyComponent } from '@app/root/components/privacy/privacy.component';
import { SupportComponent } from '@app/root/components/support/support.component';
import { TcComponent } from '@app/root/components/tc/tc.component';
import { ToastsComponent } from '@app/root/components/toasts/toasts.component';
import { ToastService } from '@app/root/providers/toast-service';
import { AppRoutingModule } from '@app/routes.module';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormlyModule } from '@ngx-formly/core';
import { AppService } from '@services/app.service';
import { AuthInterceptor } from '@services/auth.interceptor';
import { BrightViewConfigService } from '@services/brightview-config.service';
import { CopayApiService } from '@services/copay-api.service';
import { CopayCodeRequestApiService } from '@services/copay-code-request-api.service';
import { DataViewConfigService } from '@services/dataview-config.service';
import { DateService } from '@services/date.service';
import { LogService } from '@services/log.service';
import { LookupsService } from '@services/lookups.service';
import { PharmacyApiService } from '@services/pharmacy-api.service';
import { PrescriberService } from '@services/prescriber.service';
import { RetryInterceptor } from '@services/retry.interceptor';
import { HttpTenantInterceptor } from '@services/tenant.interceptor';
import { TenantService } from '@services/tenant.service';
import { UrlService } from '@services/url.service';
import { UserService } from '@services/user.service';
import { CaptchaComponent } from '@shared/components/captcha.component';
import { FormlyFieldListComponent } from '@shared/components/forms/formly-fields/formly-field-list/formly-field-list.component';
import { FormlyFieldRangeComponent } from '@shared/components/forms/formly-fields/formly-field-range.component';
import { SharedModule } from '@shared/shared.module';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { NgxPaginationModule } from 'ngx-pagination';

@NgModule({
  declarations: [
    AppComponent,
    PrivacyComponent,
    ToastsComponent,
    TcComponent,
    SupportComponent,
    FormlyFieldListComponent,
    FormlyFieldRangeComponent,
    ContactUsModalComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    MonacoEditorModule.forRoot(),
    NgxPaginationModule,
    AppRoutingModule,
    FormlyBootstrapModule,
    FormlyModule.forRoot(formlyConfig),
    LeafletModule,
    NgbPopoverModule,
    CaptchaComponent,
  ],
  providers: [
    AuthInterceptor,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTenantInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RetryInterceptor,
      multi: true,
    },
    PharmacyApiService,
    UserService,
    DateService,
    CopayCodeRequestApiService,
    UrlService,
    PrescriberService,
    LogService,
    TenantService,
    CopayApiService,
    ToastService,
    LookupsService,
    CurrencyPipe,
    DecimalPipe,
    DataViewConfigService,
    BrightViewConfigService,
    {
      provide: ErrorHandler,
      useClass: LogService,
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [AppService],
      useFactory: (appService: AppService) => async () => {
        await appService.checkVersion();
      },
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
